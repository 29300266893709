import { useState, useEffect } from 'react'
import Layout from '../../../admin-component/layout'
import { url } from '../../../helpers/url'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
function EditNotice({ type }) {
  const editId = useParams()
  const [cat, setCat] = useState({})
  const [load, setLoad] = useState(false)
  useEffect(() => {
    if (editId.id) {
      axios.get(`${url}/notice?mode=one&id=${editId.id}`).then(({ data }) => {
        if (data.success) {
          setCat(data.data)
          setLoad(true)
        }
      })
    }
  }, [editId.id])

  const [success, setSuccess] = useState(false)

  const onType = (e) => {
    setCat({ ...cat, [e.target.name]: e.target.value })
  }


  const formSubmit = (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append("mode", type)
    data.append("id", cat.id)
    data.append("notice", cat.notice)

    axios.post(`${url}/admin/notice/`, data).then(({ data }) => {
      if (data.success) {
        window.location.href = '/admin/notice'
      }
    })
  }

  return (
    <Layout>
      <div className="admin_form notice_form">
        <h3 className='heading mb-2'>Add Notice</h3>

        {(success) &&
          <div className="alert alert-success">Notice has been updated</div>
        }

        {(!success) &&
          <form onSubmit={formSubmit}>
            <div className="input_items">

              <div className="input_group">
                <label htmlFor="">Description</label>
                <textarea onChange={onType} name="notice" id="" value={cat.notice} ></textarea>
              </div>


              <div className="input_group">
                <button className='admin_btn'>{type === 'add' ? 'Add' : 'Update'}  Notice</button>
              </div>

            </div>
          </form>
        }
      </div>
    </Layout>
  );
}

export default EditNotice;