import Accordion from "../components/accordion/accordion";
import Header from "../helpers/header/header";
import { Link } from "react-router-dom";
import '../styles/thank-you.css'
function ThankYou() {
  return (
    <>
      <Header className="about_header">
        <div className="col-lg-9 body_col">
          <div className="thank_page">


            <div className="shape_wrapper">
              <div className="triangle_top"></div>
              <div className="triangle_bottom"></div>

              <div className="content_item">
                <div className="img text-center">
                  <img src="/tick.png" alt="" />
                </div>

                <div className="heading text-center">
                  <h1>Thank you!</h1>
                </div>

                <p>Thank you for your interest in joining the Daily Market Exchange!</p>
                <p>We are reviewing your Full Access Membership application.</p>
                <p>Should you be successful, an account activation link will be sent to your email. Please click the link to activate your Daily Market Exchange account.</p>
                <p>Otherwise, we will let you know (typically within 24 hours) and refund the initial $49 subscription payment if applicable.</p>

                <p>Hope to see you on the other side.</p>
                <p>Best wishes,</p>
                <p>The Daily Market Exchange Team</p>
              </div>
            </div>

          </div>



        </div>
      </Header >
    </>
  );
}

export default ThankYou;