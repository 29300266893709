import { useState, useEffect, useContext } from "react"
import axios from "axios"
import Header from "../helpers/header/header"
import { Context } from "../helpers/context"
import { url } from "../helpers/url"
import { Link } from "react-router-dom"
const Leadboard = () => {
  const [row, setRow] = useState([])
  const [load, setLoad] = useState(false)

  useEffect(() => {
    axios.get(url + `/user/rank?limit=10`)
      .then(({ data }) => {
        if (data.success) {
          setRow(data.data)
        }
        setLoad(true)
      })
  }, [])
  return (
    <>
      <Header>
        <div className="col-lg-9 body_col glory leaderboard">
          <h4 className='title glory_sub'>Top 10 Daily Market Exchange Members</h4>

          <div className="table-responsive">
            <table class="front_table table table-hover table-striped left_table table-dark">
              <thead>
                <tr>
                  <th>Ranking</th>
                  <th>Member</th>
                  <th>Points</th>
                  <th>Trade History</th>

                </tr>
              </thead>
              <tbody>
                {row.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item['registers.username']}</td>
                      <td>{item.total}</td>
                      <td><Link target="_blank" className="btn glory_btn" to={"/profile/" + item.mainUserId}>View</Link></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Header>
    </>
  )
}

export default Leadboard