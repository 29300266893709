import Accordion from "../components/accordion/accordion";
import Header from "../helpers/header/header";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../helpers/url";
function Noticeboard() {

  const [rows, setRows] = useState([])

  useEffect(() => {
    axios.get(url + '/notice?mode=view')
      .then(({ data }) => {
        if (data.success) {
          setRows(data.data)
        }

      })
  }, [])

  return (
    <>
      <Header className="about_header">
        <div className="col-lg-9 body_col">
          <div className="about_box">

            <div className="rule about_box_p">
              <h3 className="about_h3">Noticeboard</h3>
              {
                rows.map(item => {
                  return (
                    <div className="rule_row">
                      <div className="box"></div>
                      <p>{item.notice}</p>
                    </div>
                  )
                })
              }

            </div>


          </div>
        </div>
      </Header>
    </>
  );
}

export default Noticeboard;