import Header from '../../helpers/header/header'

import Feed from '../../components/feed/feed'
import { useLocation, useParams } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import { url } from '../../helpers/url';
import axios from 'axios';
import ModelReadMore from '../../components/model/read_more_model';
import { Context } from '../../helpers/context';
function Recent() {
  const [load, setLoad] = useState(false)

  const [post, setPost] = useState([])
  const editId = useParams()
  const [user, setUser] = useContext(Context)
  useEffect(() => {
    if (editId.id) {
      axios.get(url + `/post/single?postId=${editId.id}`)
        .then(({ data }) => {
          if (data.success) {
            setPost(data.rows)
          }
          setLoad(true)
        })
    }
  }, [editId.id])

  const [open, setOpen] = useState('none')

  const [edit, setEdit] = useState({})


  return (
    <>
      <Header>
        <div className="col-lg-9 body_col">
          {(load && post.length === 0) &&
            <div className="custom_alert alert alert-danger">
              No posts are found
            </div>
          }
          {(load && post.length > 0) && <>
            <Feed type="post" follow={true} admin={true} data={post} />
          </>
          }
        </div>
      </Header>
      {(post.length > 0 && post[0].read_more && !user.userId) && <ModelReadMore />}
    </>
  );
}

export default Recent;