import { useState, useEffect } from 'react'
import Layout from '../../../admin-component/layout'
import { url } from '../../../helpers/url'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'

function EditUser({ mode }) {
  const { id } = useParams()
  const [single, setSingle] = useState({})
  const [load, setLoad] = useState(false)
  const [point, setPoint] = useState(0)
  const [pointLoad, setPointLoad] = useState(false)
  useEffect(() => {
    axios.get(`${url}/admin/user/single?id=${id}`).then(({ data }) => {
      if (data.success && data.data) {
        setSingle(data.data)
        setLoad(true)
      }
    })
  }, [id])



  return (
    <Layout>
      <div className="admin_form">
        <h3 className='heading mb-2'>View User </h3>

        <table class="table table-hover table-striped left_table">

          <tbody>

            <tr>
              <td>Email</td>
              <td>{single.email}</td>

            </tr>

            <tr>
              <td>Username</td>
              <td>{single.username}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{single.status}</td>
            </tr>
            <tr>
              <td>View User</td>
              <td><Link className='btn btn-warning' target={"_blank"} to={"/profile/" + single.id}>View User</Link></td>
            </tr>
            <tr>
              <td>Selected Description</td>
              <td>

                {single.radio === 'option_1' ? 'In 50 words or less, discuss your 30-day market outlook for the S&P 500 index' : 'In 50 words or less, discuss a stock idea you recently developed or are currently researching'}
              </td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                {single.description}
              </td>
            </tr>





          </tbody>
        </table>

        <div className="back_btn">
          <Link className='admin_btn' to={"/admin/user"}>Back</Link>
        </div>
      </div>
    </Layout>
  );
}

export default EditUser;